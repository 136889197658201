<template>
  <header class="navbar">
    <div class="logo"><a href="https://gitee.com/starfi11/letz-algo" style="text-decoration: none; color: white;">LetzAlgo</a></div>
    <nav class ="nav-list">
      <div class ="nav-item" :class="{ 'router-link-active': isActive('/') }">
        <router-link to="/">主页</router-link>
      </div>
      <div class ="nav-item" :class="{ 'router-link-active': isActive('/about') }" v-if = false>
        <router-link to="/about">关于我们</router-link>
      </div>
      <div class ="nav-item" :class="{ 'router-link-active': this.$route.path.startsWith('/recommend') }">
        <router-link to="/recommend">题目推荐</router-link>
      </div>
      <div class ="nav-item" :class="{ 'router-link-active': this.$route.path.startsWith('/algorithm-data') }">
        <router-link to="/algorithm-data">学情分析</router-link> 
      </div>
      <div class ="nav-item" :class="{ 'router-link-active': isActive('/search') }">
        <router-link to="/search">题目搜索</router-link> 
      </div>
      <div class ="nav-item" :class="{ 'router-link-active': isActive('/Notification') }">
        <router-link to="/Notification" title="新页面" v-if = "isLoggedIn">
          <i class="bell-icon" v-if = "!hasMessage"></i> <!-- 这里是铃铛图标 -->
          <i class="bell-icon2" v-else></i> <!-- 这里是铃铛图标 -->
        </router-link>
      </div>
    </nav>
    <AuthBox /> <!-- 登录/注册小框 -->
  </header>
</template>

<script>
import AuthBox from './AuthBox.vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: 'NavBar',
  components: {
    AuthBox
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      hasMessage: state => state.hasMessage,
    })
  },
  methods: {
    ...mapActions(['setUnread', 'clearUnread']), // 从 Vuex 中获取 login 方法

    isActive(route) {
      return this.$route.path === route || this.isHovered;
    },
    async ifMessage() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/notification/notifications/has_unread`, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        }
      })
      .then(response => {
        console.log(response.data);
        if (response.data.has_unread) {
          this.setUnread();
        } else {
          this.clearUnread(); 
        }
      })
      .catch(error => {
        console.error('获取信息失败:', error);
      });
    },
  },
  mounted() {
    if(this.isLoggedIn)
      this.ifMessage();
    console.log("登录:",this.isLoggedIn);
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #3fc2d9;
  color: white;
  align-items: center;   /* 在垂直方向上居中对齐 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.logo {
  font-size: 26px;
  font-weight: bold;
  font-family: 'LOGO';
}

.nav-list {
  display: flex;
}

nav a {
  margin: 16 60px;
  color: white;
  text-decoration: none;
  font-size:16px;

  padding: 18px 60px;
  border-radius: 6px;
  font-weight: bold;
}
nav div {
  margin-left: 0px;
  padding: 18px 0px;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  background-color: #3fc2d9;
  color: white;
  font-size: 14px;
  
  transition: background-color 0.3s; /* 过渡效果 */
}

.router-link-active,nav div:hover {
  background-color: #3899b4; /* 深色背景 */
}

.nav-item .bell-icon {
  /* 添加铃铛图标的样式 */
  width: 24px; /* 设置宽度 */
  height: 24px; /* 设置高度 */
  display: inline-block; /* 使其为块级元素 */
  background: url('@/assets/imgs/bell.png') no-repeat center center; /* 设置图标的背景 */
  background-size: contain; /* 确保图标完整显示 */
}

.nav-item .bell-icon2 {
  /* 添加铃铛图标的样式 */
  width: 24px; /* 设置宽度 */
  height: 24px; /* 设置高度 */
  display: inline-block; /* 使其为块级元素 */
  background: url('@/assets/imgs/bell2.png') no-repeat center center; /* 设置图标的背景 */
  background-size: contain; /* 确保图标完整显示 */
}

</style>
