<template>
  <div class="container">
    <!-- 锁定时的遮罩层，覆盖整个页面 -->
    <div v-if="locked" class="overlay">
      <p>正在初始化，请稍候...</p>
    </div>

    <h2>绑定洛谷账号</h2>
    <p class="text_1">
      当前绑定状态: {{ luogu_uid ? '已绑定' : '未绑定' }}
    </p>
    <p class="text_1" v-if="luogu_uid">已绑定UID: {{ luogu_uid }}</p>

    <!-- 绑定UID表单 -->
    <form @submit.prevent="handleBinding" v-if="!locked">
      <div class="input-group">
        <label for="username">洛谷UID:</label>
        <input type="text" id="input_uid" v-model="input_uid" required />
      </div>
      <button type="submit" :disabled="locked">绑定</button>
    </form>

    <!-- 锁定时的加载提示 -->
    <!-- 锁定时的遮罩层已经在页面顶部添加，无需单独的提示 -->
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      input_uid: '',          // 用户输入的UID
      luogu_uid: null,        // 绑定的UID
      locked: false,          // 页面是否锁定
      intervalId: null,       // 定时器ID，用于轮询状态
    };
  },
  computed: {
    ...mapState(['access_token']), // 映射access_token
  },
  methods: {
    // 绑定UID的处理方法
    async handleBinding() {
      // 发起绑定请求
      axios.post(`${process.env.VUE_APP_API_URL}/api/user/bind_luogu_uid/`, {
        luogu_uid: this.input_uid,
      }, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        },
      })
      .then(response => {
        this.luogu_uid = response.data.luogu_uid;
        alert('绑定成功！');

        // 锁定用户操作，等待后台初始化
        this.locked = true;

        // 启动轮询检查后台初始化是否完成
        this.startPolling();

        // 禁用所有用户交互
        this.disableUserInteraction();
      })
      .catch(error => {
        console.error('绑定失败:', error);
        alert('绑定失败，请重试。');
      });
    },

    // 获取计算状态
    async getComputingStatus() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/management/frontend_get_computing_status/`, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.access_token,
          },
        });

        console.log('计算状态:', response.data);  // 添加调试输出，查看返回的数据

        // 假设返回的数据格式是 { status: 0 }
        if (response.data.is_computing === false) {
          // 计算完成，解除锁定
          this.locked = false;
          clearInterval(this.intervalId); // 清除定时器
          alert('初始化完成，您可以继续操作');

          // 恢复用户交互
          this.enableUserInteraction();

          // 跳转到其他页面
          this.$router.push('/userCenter');
        }
      } catch (error) {
        console.error('获取计算状态失败:', error);
      }
    },

    // 启动轮询
    startPolling() {
      if (this.intervalId !== null) {
        console.log('轮询已经启动，跳过重复启动');
        return;  // 如果已经启动了轮询，就不再启动新的定时器
      }

      this.intervalId = setInterval(() => {
        console.log('正在检查计算状态...');
        this.getComputingStatus();
      }, 1000); // 每秒检查一次计算状态
    },

    // 获取用户信息（用于页面初始化时加载UID）
    async fetchUserinfo() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/user/self_information/`, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        },
      })
      .then(response => {
        this.luogu_uid = response.data.luogu_uid;
      })
      .catch(error => {
        console.error('获取个人信息失败:', error);
        alert('无法获取个人信息，请重试。');
      });
    },

    // 禁用用户交互
    disableUserInteraction() {
      // 禁用页面所有的交互（输入框、按钮、链接等）
      const inputs = document.querySelectorAll('input, button, select, textarea, a');
      inputs.forEach(input => input.disabled = true);

      // 禁止鼠标事件
      document.body.style.pointerEvents = 'none';

      // 禁止键盘事件
      document.body.addEventListener('keydown', this.preventDefault, true);

      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    },

    // 恢复用户交互
    enableUserInteraction() {
      // 启用页面所有的交互（输入框、按钮、链接等）
      const inputs = document.querySelectorAll('input, button, select, textarea, a');
      inputs.forEach(input => input.disabled = false);

      // 恢复鼠标事件
      document.body.style.pointerEvents = '';

      // 恢复键盘事件
      document.body.removeEventListener('keydown', this.preventDefault, true);

      // 恢复页面滚动
      document.body.style.overflow = '';
    },

    // 阻止默认行为的函数
    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },
  },
  mounted() {
    this.fetchUserinfo();  // 页面加载时获取用户信息

    // 可以在页面加载时就禁用交互
    // this.disableUserInteraction(); // 如果需要
  },
};
</script>

<style scoped>
.container {
  width: 350px;
  margin: 120px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.input-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

/* 锁定状态的遮罩层 */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

/* 禁用链接的样式 */
.disabled-link {
  pointer-events: none;
  color: #999;
}
</style>
