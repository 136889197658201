<template>
  <div id="AboutView">
  </div>
</template>

<script>

export default {
  name: 'AboutView',
};
</script>
