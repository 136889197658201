<template>
  <div class="verification-container">
    <h2>邮箱验证</h2>
    <form @submit.prevent="verifyEmail">
      <div class="input-group">
        <label for="verificationCode">验证码:</label>
        <input type="text" id="verificationCode" v-model="verificationCode" required />
      </div>
      <button type="submit">验证邮箱</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VerificationForm',
  data() {
    return {
      verificationCode: '', // 用户输入的验证码
      username: sessionStorage.getItem('username'), // 从 sessionStorage 获取用户名
      email: sessionStorage.getItem('email') // 从 sessionStorage 获取邮箱
    };
  },
  methods: {
    async verifyEmail() {
      const verificationBody = {
        username: this.username,
        email: this.email,
        code: this.verificationCode // 获取用户输入的验证码
      };
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/user/verify_email/`, verificationBody);
        console.log('邮箱验证成功:', response.data);
        alert('邮箱验证成功！');
        
        // 清除缓存并跳转到登录页面
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('email');
        this.$router.push('/login');
      } catch (error) {
        console.error('验证失败:', error);
        alert('验证失败：' + JSON.stringify(error.response?.data || error.message || '验证码验证失败，请重试'));
      }
    }
  }
};
</script>

<style scoped>
.verification-container {
  width: 350px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.input-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}
</style>
