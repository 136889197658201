<template>
  <div>
    <router-view /> <!-- 使用子路由来显示注册和验证 -->
  </div>
</template>

<script>
export default {
  name: 'RegisterView'
};
</script>
