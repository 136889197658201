<template>
  <div class="user-center-header">
    <img :src="avatar_url" class = "avatar" alt="头像">
    <p class = "header-username">{{userinfo ? userinfo.username : '未登录'}}</p>
  </div>
  <div class="body-container">
    <div class="parts-container">
      <div
        class="parts"
        :style="{flex:0.8}"
      >
        <h2>头像</h2>
        <img v-if="avatar_url" :src="avatar_url" class = "avatar_o" alt="头像">
        <p></p>
        <form id="uploadForm" enctype="multipart/form-data">
          <input type="file" name="avatar" id="fileInput">
          <p></p>
          <div class="button-group">
            <button type="button" @click="upLoadAvatar">上传</button>
            <button type="button" @click="deleteAvatar">恢复默认头像</button>
          </div>
        </form>
      </div>
      <div
        class="parts"
        :style="{flex:1.5}"
      >
        <h2>个人信息</h2>
        <p>用户名：{{userinfo ? userinfo.username : '未登录'}}</p>
        <p>邮箱：{{userinfo ? userinfo.email : '未登录'}}</p>
        <div class="button-group">
          <button type="button" v-if = false>编辑</button>
        </div>
      </div>
      <div
        class="parts"
        :style="{flex:1.2}"
      >
        <h2>洛谷账号</h2>
        <p>uid:{{userinfo ? ( userinfo.luogu_uid ? userinfo.luogu_uid : '未绑定') : '未登录'}}</p>
        <p>绑定状态：{{userinfo ? ( userinfo.luogu_uid ? "已绑定" : '未绑定') : '未登录'}}</p>
        <div class="button-group">
          <button type="button" @click="goBinding">{{userinfo ? ( userinfo.luogu_uid ? "去换绑" : '去绑定') : '未登录'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      userinfo:null,
      username:null,
      email:null,
      intro:null,
      isBinded:false,
      luoguUid:null,
    }
  },
  computed: {
    ...mapState(['access_token','avatar_url']), // 映射状态
  },
  methods: {
    ...mapActions(['updataUserinfo','saveAvatarUrl']), // 映射方法
    //绑定洛谷账号
    goBinding() {
      this.$router.push('/bindLuogu'); // 跳转
    },
    async fetchUserinfo() {
        axios.get(`${process.env.VUE_APP_API_URL}/api/user/self_information/`, {
          headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        }
      })
      .then(response => {
        // 请求成功处理
        this.userinfo = response.data;
      })
      .catch(error => {
        // 请求失败处理
        console.error('获取个人信息失败:', error);
        alert('无法获取个人信息，请重试。');
      });
    },
    async fetchAvatar() {
      //获取头像
      axios.get(`${process.env.VUE_APP_API_URL}/api/user/get_avatar/`, {
          headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        }
      })
      .then(response => {
        this.saveAvatarUrl(response.data.avatar_url);
      })
      .catch(error => {
        console.error(error);
      });
    },
    upLoadAvatar() {
      //上传头像
      const form = new FormData(document.getElementById('uploadForm'));
      form.append('avatar', document.getElementById('fileInput').files[0]);

      axios.post(`${process.env.VUE_APP_API_URL}/api/user/upload_avatar/`, form, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
          'Content-Type':'multipart/form-data',
        }
      })
     .then(response => {
        alert('头像上传成功！');
        console.log("上传头像响应:",response);
        this.fetchAvatar();
      })
     .catch(error => {
        console.error(error);
        alert('头像上传失败，请重试。');
      });
    },
    async deleteAvatar() {
      //获取头像
      axios.delete(`${process.env.VUE_APP_API_URL}/api/user/delete_avatar/`, {
          headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        }
      })
      .then(response => {
        console.log("删除头像响应:",response);
        alert('头像已恢复默认！');
        this.fetchAvatar();
      })  
      .catch(error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.fetchUserinfo();
    this.fetchAvatar();
  },
}
</script>
  
<style scoped>
  .user-center-header {
    width: 85%;
    margin: -6px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-image: url('@/assets/imgs/bg2.jpg');
    background-size: cover; /* 使背景图像覆盖整个盒子 */

    background-position: 50% 42%; /* 水平居中，垂直偏移 25% */

    height: 165px; /* 设置盒子的高度 */
    border-radius: 6px;
  }

  .avatar {
    margin: 0 5px; /* 图标和文字之间的间距 */
    width: 75px; /* 设置图片宽度; */
    aspect-ratio: 1/1; /* 保持纵横比 */
    border-radius: 50%;

    border:2px solid #a1a1a1bc; /* 边框 */

    position: absolute; /* 使用绝对定位 */
    top: 180px; 
    left: 130px; 
  } 

  .header-username {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 175px;
    left: 230px;
  }

  .body-container {
    margin: 20px auto;
    padding: 0px;
    width: 87.5%;
  }

  .parts-container {
    display: flex;
    justify-content: space-between; /* 两端对齐 */
  }

  .parts{ 
    margin: 0px; /* 部分之间的间距 */
    margin-right: 30px; /* 右侧部分的间距 */
    padding: 40px; /* 内边距 */
    padding-left: 80px; /* 左侧部分的内边距 */
    padding-top:20px;

    background-color: #f9f9f9; /* 背景颜色 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 阴影效果 */
    position: relative; /* 为了方便定位 */
    border-radius: 6px;
    font-size:20px;
    font-weight: bold;

    text-align: left; /* 左对齐 */
  }

  .parts:last-child {
    margin-right: 0px;
  }

  .avatar_o {
    margin: 0 5px; /* 图标和文字之间的间距 */
    width: 28%; /* 设置图片宽度; */
    aspect-ratio: 1/1; /* 保持纵横比 */
    border-radius: 4px;
    background-color: aquamarine;

    border:2px solid #a1a1a1bc; /* 边框 */
  } 
  
  .button-group button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    margin-right: 20px;
  }

  .button-group button:last-child {
    margin-right: 0px;
    background-color: #e7a62f;
  }
  
  h2 {
    color: #27a872;
  }

  p {
    color: #555; /* 段落颜色 */
  }
</style>
  