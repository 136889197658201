// router.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import RegisterForm from '@/views/RegisterForm.vue';
import VerificationForm from '@/views/VerificationForm.vue';
import NotificationView from '@/views/NotificationView.vue'
import UserCenter from '@/views/UserCenterView.vue';
import store from '@/store';
import AboutView from './views/AboutView.vue';
import BindLuoguView from './views/user/BindLuoguView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
  path: '/about',
  name: 'About',
  component: AboutView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    component: RegisterView,
    children: [
      {
        path: '',
        name: 'register',
        component: RegisterForm
      },
      {
        path: 'verify',
        name: 'verify',
        component: VerificationForm,
        props: route => ({ username: route.params.username, email: route.params.email })
      }
    ],
    meta: { requiresAuth: false }
  },
  {
    path: '/recommend',
    component: () => import('@/views/recommend/RecommendMain.vue'),
    children: [
      {
        path: '',
        name: 'RecommendView',
        component: () => import('@/views/recommend/RecommendView.vue')
      },
      {
        path: 'history',
        name: 'RecommendationHistory',
        component: () => import('@/views/recommend/RecommendationHistory.vue')
      },
      {
        path: 'submit-tag',
        name: 'SubmitTagData',
        component: () => import('@/views/recommend/SubmitTagData.vue')
      },
      {
        path: 'tag-dependency',
        name: 'TagDependency',
        component: () => import('@/views/recommend/TagDependency.vue')
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/algorithm-data',
    component: () => import('@/views/user/UserAlgorithmData.vue'),
    children: [
      {
        path: '',
        name: 'PassedProblems',
        component: () => import('@/views/user/PassedProblems.vue')
      },
      {
        path: 'tag-difficulty-analysis',
        name: 'TagDifficultyAnalysis',
        component: () => import('@/views/user/TagDifficultyAnalysis.vue')
      },
      {
        path: 'DependencyGraph',
        name: 'DependencyGraph',
        component: () => import('@/views/user/DependencyGraph.vue')
      },
      {
      path: 'user-statistics',
      name: 'UserStatistics',
      component: () => import('@/views/user/UserStatistics.vue')
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/notification', // 新增的路径
    name: 'NotificationView',   // 新增的名称
    component: NotificationView, // 新页面组件
    meta: { requiresAuth: true } // 根据需要添加认证要求
  },
  {
    path: '/userCenter',
    name: 'UserCenter',
    component: UserCenter,
    meta: { requiresAuth: true }
  },
  {
    path: '/bindLuogu',
    name: 'BindLuogu',
    component: BindLuoguView,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'ProblemSearch',
    component: () => import('@/views/recommend/ProblemSearch.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
