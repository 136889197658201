import axios from 'axios';
import store from './store'; // 假设你将令牌存储在 Vuex 中

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

// 请求拦截器：为每个请求添加 Authorization 头部
instance.interceptors.request.use(
  config => {
    // 确保刷新令牌请求不带 Authorization 头部
    if (config.url === '/api/token/refresh/') {
      return config; // 不做任何修改，直接返回配置
    }

    const token = store.state.access_token; // 从 Vuex 中获取 access_token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// 响应拦截器：处理token过期并尝试刷新token
instance.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    // 如果响应是401错误，表示token过期
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // 获取存储的refresh_token
      const refreshToken = store.state.refresh_token;

      // 输出refresh_token的调试信息
      console.log('Attempting to refresh token...');
      console.log('Stored refresh_token:', refreshToken);

      if (!refreshToken) {
        // 如果没有 refresh_token，意味着需要用户重新登录
        console.log('No refresh_token found, redirecting to login.');
        store.commit('logout'); // 清除登录信息
        window.location.href = '/login'; // 跳转到登录页
        return Promise.reject(error);
      }

      try {
        // 使用 refresh_token 请求新的 access_token
        console.log('Sending refresh request...');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/token/refresh/`, { refresh: refreshToken }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });


        // 如果刷新成功，更新 Vuex 中的 access_token 和 refresh_token
        const { access: newAccessToken, refresh: newRefreshToken } = response.data;

        console.log('Token refreshed successfully:', newAccessToken);

        store.commit('login', { // 使用 login mutation 更新 token
          username: store.state.username,
          access_token: newAccessToken,
          refresh_token: newRefreshToken
        });

        // 更新原始请求的 Authorization 头部
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        // 重试原始请求
        return axios(originalRequest);
      } catch (refreshError) {
        console.log('Error refreshing token:', refreshError);
        // 如果刷新 token 失败，通常是因为 refresh_token 也过期了
        store.commit('logout'); // 清除所有的登录信息
        window.location.href = '/login'; // 跳转到登录页
        return Promise.reject(refreshError);
      }
    }

    // 如果不是 401 错误，直接返回错误
    return Promise.reject(error);
  }
);

export default instance;
