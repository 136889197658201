// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 引入 router
import store from './store';
import "@/assets/font/font.css"; // 引入字体样式

createApp(App)
  .use(store)
  .use(router) // 注入 router
  .mount('#app');
