<template>
  <div class="login-container">
    <h2>登录</h2>
    <form @submit.prevent="handleLogin">
      <div class="input-group">
        <label for="username">用户名:</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="input-group">
        <label for="password">密码:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <button type="submit" :disabled="locked">登录</button>
    </form>

    <!-- 锁定时的加载提示 -->
    <div v-if="locked" class="overlay">
      <p>正在初始化，请稍候...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
      locked: false,  // 控制锁定状态
      intervalId: null,  // 定时器ID，用于轮询
    };
  },
  methods: {
    ...mapActions(['login', 'saveAvatarUrl']), // 从 Vuex 中获取 login 方法

    // 禁用所有用户交互
    disableUserInteraction() {
      const inputs = document.querySelectorAll('input, button, select, textarea, a');
      inputs.forEach(input => input.disabled = true);
      document.body.style.pointerEvents = 'none';  // 禁止鼠标事件
      document.body.addEventListener('keydown', this.preventDefault, true);  // 禁止键盘事件
      document.body.style.overflow = 'hidden';  // 禁止页面滚动
    },

    // 恢复所有用户交互
    enableUserInteraction() {
      const inputs = document.querySelectorAll('input, button, select, textarea, a');
      inputs.forEach(input => input.disabled = false);
      document.body.style.pointerEvents = '';  // 恢复鼠标事件
      document.body.removeEventListener('keydown', this.preventDefault, true);  // 恢复键盘事件
      document.body.style.overflow = '';  // 恢复页面滚动
    },

    // 阻止默认行为的函数
    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    async handleLogin() {
      try {
        console.log('尝试登录...');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/user/login/`, {
          username: this.username,
          password: this.password
        });

        console.log('登录响应:', response.data);

        // 假设后端返回 access_token、refresh_token 和 first_login
        const { access_token, refresh_token, first_login, username } = response.data;

        if (access_token && refresh_token) {
          console.log('登录成功，存储token和用户名');
          this.login({ username, access_token, refresh_token });
          alert('登录成功！');
          this.fetchAvatar(); // 获取头像

          if (first_login) {
            console.log('首次登录，开始初始化流程');
            this.locked = true;  // 锁定界面
            this.disableUserInteraction();  // 禁用用户交互
            this.startPolling();  // 启动轮询检查计算状态
          } else {
            console.log('不是首次登录，直接跳转');
            this.$router.push('/');  // 不是首次登录，直接跳转到主页
          }
        }
      } catch (error) {
        console.error('登录失败:', error);
        alert('登录失败：' + JSON.stringify(error.response?.data || error.message || '未知错误'));
      }
    },

    async fetchAvatar() {
      console.log('正在获取头像...');
      // 获取头像
      axios.get(`${process.env.VUE_APP_API_URL}/api/user/get_avatar/`, {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.access_token,
        }
      })
      .then(response => {
        this.saveAvatarUrl(response.data.avatar_url);

      })
      .catch(error => {
        console.error('获取头像失败:', error);
      });
    },

    // 获取计算状态
    async getComputingStatus() {
      console.log('检查计算状态...');
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/management/frontend_get_computing_status/`, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.access_token,
          },
        });

        console.log('计算状态响应:', response.data);

        if (response.data.is_computing === false) {
          console.log('计算完成，解除锁定');
          // 计算完成，解除锁定
          this.locked = false;
          this.enableUserInteraction();  // 恢复用户交互
          clearInterval(this.intervalId); // 清除定时器
          alert('初始化完成，您可以继续操作');
          this.$router.push('/');  // 计算完成后跳转
        }
      } catch (error) {
        console.error('获取计算状态失败:', error);
      }
    },

    // 启动轮询
    startPolling() {
      console.log('启动轮询...');
      this.intervalId = setInterval(() => {
        this.getComputingStatus();
      }, 1000); // 每秒钟检查一次计算状态
    }
  }
};
</script>

<style scoped>
.login-container {
  width: 350px;
  margin: 120px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.input-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

/* 锁定状态的遮罩层 */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
