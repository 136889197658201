<template>
  <div class="register-container">
    <h2>注册</h2>
    <form @submit.prevent="handleRegister">
      <div class="input-group">
        <label for="username">用户名:</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="input-group">
        <label for="email">邮箱:</label>
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="input-group">
        <label for="password">密码:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <div class="input-group">
        <label for="password2">确认密码:</label>
        <input type="password" id="password2" v-model="password2" required />
      </div>
      <div class="input-group">
        <label for="luogu_uid">洛谷UID:</label>
        <input type="text" id="luogu_uid" v-model="luogu_uid" required />
      </div>
      <div class="input-group">
        <label for="luogu_uid2">确认洛谷UID:</label>
        <input type="text" id="luogu_uid2" v-model="luogu_uid2" required />
      </div>
      <button type="submit">注册</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegisterForm',
  data() {
    return {
      username: '',
      email: '',
      password: '',
      password2: '',
      luogu_uid: '',
      luogu_uid2: ''
    };
  },
    methods: {
    async handleRegister() {
    const body = {
        username: this.username,
        email: this.email,
        password: this.password,
        password2: this.password2,
        luogu_uid: this.luogu_uid,
        luogu_uid2: this.luogu_uid2
    };
    try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/user/register/`, body);
        console.log('注册成功:', response.data);

        // 将用户名和邮箱保存到 sessionStorage，以便在验证时使用
        sessionStorage.setItem('username', this.username);
        sessionStorage.setItem('email', this.email);

        // 跳转到验证页面
        this.$router.push({ name: 'verify' });
        alert(response.data.message || '注册成功！请检查您的邮箱以获取验证码。');
    } catch (error) {
        console.error('注册失败:', error);
        alert('注册失败：' + JSON.stringify(error.response?.data || error.message || '未知错误'));
    }
    }   
  }
};
</script>

<style scoped>
.register-container {
  width: 350px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.input-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}
</style>
