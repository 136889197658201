<template>
  <div class="home-container">
    <h1 class="welcome-title">LetzAlgo</h1>
    <p class="description">这是一个简单的个性化算法数据分析与推荐系统</p>

    <!-- 展示模块 -->
    <div class="carousel-container">
      <div class="carousel">
        <transition name="fade" mode="out-in">
          <div class="carousel-item" v-if="items.length">
            <div class="text-box">
              {{ items[currentIndex].text }}
            </div>
            <div class="image-box">
              <img :src="items[currentIndex].image" alt="功能展示" />
            </div>
          </div>
        </transition>
      </div>

      <!-- 切换按钮 -->
      <div class="nav-wrapper">
        <button @click="prev" class="nav-button">&#9664;</button> <!-- 左箭头 -->
        <button @click="next" class="nav-button">&#9654;</button> <!-- 右箭头 -->
      </div>

      <!-- Carousel Dots -->
      <div class="carousel-dots">
        <span
          v-for="(item, index) in items"
          :key="index"
          :class="['dot', { active: currentIndex === index }]"
          @click="goToIndex(index)"
        ></span>
      </div>
    </div>

    <!-- Loader -->
    <div v-if="isLoading" class="loader"></div>

    <!-- 空容器，用于分隔 -->
    <div class="separator"></div>

    <!-- 评论/反馈功能 -->
    <div class="feedback-section">
      <div class="rating" @mouseleave="resetHover">
        <span
          v-for="star in stars"
          :key="star"
          @mouseover="hoverRating(star)"
          @click="setRating(star)"
          class="star"
          :class="{ filled: star <= rating || star <= hoverRatingValue }"
        >
          ★
        </span>
      </div>
      <textarea v-model="comment" placeholder="请输入您的评价..." class="comment-box"></textarea>
      <div class="submit-container">
        <button @click="submitEvaluation" class="submit-button">提交评价</button>
      </div>
      <p @click="toggleFeedback" class="feedback-text">如果有对于功能的反馈和意见请点击这里提交</p>
      <div v-if="showFeedbackInput" class="feedback-input">
        <textarea v-model="feedback" placeholder="请输入您的反馈..." class="feedback-box"></textarea>
        <div class="feedback-type">
          <label>
            <input type="radio" v-model="feedbackType" value="Feature Request" />
            功能建议
          </label>
          <label>
            <input type="radio" v-model="feedbackType" value="Bug Report" />
            故障反馈
          </label>
        </div>
        <div class="submit-container">
          <button @click="submitFeedback" class="submit-button">提交反馈</button>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import axios from '@/axios';
import image1 from '@/assets/imgs/func1.png';
import image2 from '@/assets/imgs/func2.png';
import image3 from '@/assets/imgs/func3.png';
import image4 from '@/assets/imgs/func4.png';
import image5 from '@/assets/imgs/func5.png';
import image6 from '@/assets/imgs/bg4.jpg';

export default {
  name: 'HomeView',
  data() {
    return {
      stars: [1, 2, 3, 4, 5],
      rating: 0,
      hoverRatingValue: 0,
      comment: '',
      feedbackType: '',
      showFeedbackInput: false,
      feedback: '',
      errorMessage: '',
      items: [
        { text: '个性化题目推荐', image: image1 },
        { text: '已通过题目展示', image: image2 },
        { text: '个性化数据分析', image: image3 },
        { text: '题目检索', image: image4 },
        { text: '基于依赖的推荐', image: image5 },
        { text: '', image: image6 },
      ],
      currentIndex: 0,
      isLoading: false, // 用于表示图片加载状态
    };
  },
  methods: {
    hoverRating(star) {
      this.hoverRatingValue = star;
    },
    resetHover() {
      this.hoverRatingValue = 0;
    },
    setRating(star) {
      this.rating = Number(star);
      this.hoverRatingValue = 0;
    },
    toggleFeedback() {
      this.showFeedbackInput = !this.showFeedbackInput;
      if (!this.showFeedbackInput) {
        this.feedback = '';
        this.feedbackType = '';
      }
    },
    async submitEvaluation() {
      if (this.rating === 0) {
        this.errorMessage = '请打分。';
        return;
      }
      if (!this.comment) {
        this.errorMessage = '请填写评价。';
        return;
      }
      let feedbackType = this.rating >= 4 ? 'positive' : (this.rating <= 2 ? 'negative' : 'normal');

      const evaluationData = {
        rating: parseFloat(this.rating).toFixed(1),
        feedback_type: feedbackType,
        content: this.comment,
      };

      try {
        await axios.post('/api/feedback/create/', evaluationData);
        this.resetForm();
      } catch (error) {
        this.errorMessage = '提交评价时出错，请稍后重试。';
      }
    },
    async submitFeedback() {
      if (!this.feedbackType) {
        this.errorMessage = '请先选择反馈类型。';
        return;
      }
      if (!this.feedback) {
        this.errorMessage = '请填写反馈。';
        return;
      }

      const feedbackData = {
        feedback_type: this.feedbackType,
        content: this.feedback,
        rating: '0.0',
      };

      try {
        await axios.post('/api/feedback/create/', feedbackData);
        this.resetForm();
      } catch (error) {
        this.errorMessage = '提交反馈时出错，请稍后重试。';
      }
    },
    resetForm() {
      this.rating = 0;
      this.hoverRatingValue = 0;
      this.comment = '';
      this.feedbackType = '';
      this.feedback = '';
      this.showFeedbackInput = false;
      this.errorMessage = '';
    },
    next() {
      this.isLoading = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
        this.isLoading = false;
      }, 1000); // 模拟加载时间
    },
    prev() {
      this.isLoading = true;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
        this.isLoading = false;
      }, 1000); // 模拟加载时间
    },
    goToIndex(index) {
      this.currentIndex = index;
    },
  },
  mounted() {
    setInterval(this.next, 3000); // 每3秒切换一次
  },
};
</script>

<style scoped>
.home-container {
  max-width: 1000px; /* 稍微增加宽度 */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.welcome-title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.description {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

.carousel-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.carousel-item {
  display: flex;
  transition: opacity 0.5s ease;
  width: 100%;
}

.text-box {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 20px;
  color: #333;
  font-size: 24px;
  line-height: 1.5;
}

.image-box {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height:400px;
}

.image-box img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.nav-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 103%;
  padding: 0 10px;
  left: -17px;
}

.nav-button {
  background: transparent;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
}

.nav-button:hover {
  color: #007bff;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff;
}

.loader {
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-top: 20px;
}

.separator {
  margin-top: 50px;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 50px;
}

.feedback-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rating {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.star {
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
}

.star.filled {
  color: #ffbf00;
}

.comment-box {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}

.submit-container {
  text-align: center;
}

.submit-button {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.feedback-text {
  text-align: center;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
}

.feedback-input {
  margin-top: 20px;
}

.feedback-box {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
}

.feedback-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.feedback-type label {
  font-size: 16px;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
</style>

