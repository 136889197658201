<template>
  <div 
    class="menu-item" 
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    ref="menuItem"
  >
    <img :src="avatar_url" v-if="isLoggedIn" class = "avatar-img" alt="头像">
    <button @click="goUserCenter" class="user-info-head-button">
      {{ isLoggedIn ? userName : "未登录" }}
    </button>
    <div v-if="showDropdown" class="dropdown" ref="dropdown">
      <button @click="goUserCenter" v-if="isLoggedIn" class="user-info-button">
        个人中心
      </button>
      <button @click="goLogin" v-else class="user-info-button">
        登录
      </button>
      <button @click="goRegister" v-if=" !isLoggedIn" class="user-info-button">
        注册
      </button>
      <button @click="handleLogout" v-if="isLoggedIn" class="user-info-button">
        退出账号
      </button> 
    </div>
  </div>
</template>



<script>
import { mapState, mapActions } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'AuthBox',
  setup() {
    const showDropdown = ref(false);
    let timer = null;
    
    const handleMouseLeave = () => {
      timer = setTimeout(() => {
        if (!isMouseOverMenuOrDropdown.value) {
          showDropdown.value = false;
        }
      }, 300);
    };

    const handleMouseEnter = () => {
      clearTimeout(timer);
      showDropdown.value = true;
    };
    
    const menuItem = ref(null);
    const dropdown = ref(null);
    
    const isMouseOverMenuOrDropdown = ref(false);
    
    const handleMouseOver = () => {
      isMouseOverMenuOrDropdown.value = true;
    };

    const handleMouseOut = () => {
      isMouseOverMenuOrDropdown.value = false;
      handleMouseLeave();
    };

    return {
      showDropdown,
      handleMouseLeave,
      handleMouseEnter,
      handleMouseOver,
      handleMouseOut,
      menuItem,
      dropdown,
      isMouseOverMenuOrDropdown,
    };
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      userName: state => state.username || '', // 使用 Vuex 中的用户名
      avatar_url: state => state.avatar_url || '' // 使用 Vuex 中的头像
    })
  },
  methods: {
    ...mapActions(['logout','clearUnread']), // 映射动作
    goUserCenter() {
      this.$router.push('/usercenter');// 跳转到个人中心页
    },
    goLogin() {
      this.$router.push('/login'); // 跳转到登录页
    },
    goRegister() {
      this.$router.push('/register'); // 跳转到注册页
    },
    handleLogout() {
      this.logout(); // 调用 vuex 的 logout 方法
      this.$router.push('/'); // 跳转到首页
    },
  },
};
</script>


<style scoped>


.menu-item {
    position: relative; /* 相对定位 */
    display: flex;
    align-items: center;/* 子元素在垂直方向上居中对齐 */
    cursor: pointer; /* 鼠标手型指针 */
    margin-right: 40px; /* 右侧间距 */

    height:100%; /* 高度 */
    width:160px; /* 宽度 */
    margin-right: 0px; /* 右边距 */
  }

  .avatar-img {
    margin: 0 5px; /* 图标和文字之间的间距 */
    width: 30%; /* 设置图片宽度; */
    height: auto; /* 根据宽度自动调整高度 */
    border-radius: 50%;
    background-color: aquamarine;
    aspect-ratio: 1/1; /* 保持纵横比 */
    border:2px solid #a1a1a1bc; /* 边框 */
  }   

  .dropdown {
    position: absolute; /* 绝对定位，基于 menu-item */
    top: 52px; /* 下方展开 精校 */

    background-color: #3fc2d9; /* 标题背景颜色 */
    border: 1px solid #ccc; /* 边框 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
    z-index: 100; /* 确保在其他元素之上 */
    width: 178px; /* 精校 */
  }
  
  .dropdown-item {
    display: block; /* 使链接占满整行 */
    padding: 10px; /* 内边距 */
    color: white  ; /* 文字颜色 */
    text-decoration: none; /* 去掉下划线 */
  }
  
  .dropdown-item:hover {
    background-color: #3899b4; /* 深色背景 */
  }

  .user-info-head-button {
    font-size: 20px; /* 字体大小 */
    width: 100%; /* 设置按钮的宽度 */
    height: 42px; /* 设置按钮的高度 */
    background-color: #3fc2d9; /* 按钮背景色 */
    color: white; /* 字体颜色 */
    border: none; /* 去掉边框 */
    cursor: pointer; /* 鼠标悬停时显示手型 */
  }

  .user-info-button {
    font-size: 18px; /* 字体大小 */
    width: 100%; /* 设置按钮的宽度 */
    height: 42px; /* 设置按钮的高度 */
    background-color: #3fc2d9; /* 按钮背景色 */
    color: white; /* 字体颜色 */
    border: none; /* 去掉边框 */
    cursor: pointer; /* 鼠标悬停时显示手型 */

    border: 1px solid rgb(128, 128, 128, 0.2);
  }

  .user-info-button:hover {
    background-color: #3899b4; /* 深色背景 */
    transition: background-color 0.3s; /* 过渡效果 */
  }
</style>
