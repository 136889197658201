<template>
  <div id="app" class = "app-container">
    <header>
        <NavBar/> <!-- 导航栏 -->
    </header>
    <main>
      <router-view /> <!-- 页面视图 -->
    </main>
    <footer class="app-footer">
      <p>&copy; 2024 LetzAlgo. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar
  },  
  created() {
    document.title = 'LetzAlgo';
  },
};
</script>

<style>
.app-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;/* 将标题或其他文本内容居中 */
  color: #2c3e50;
  margin-top: 0;

  min-height: 100vh; /* 使整个视图高度至少为视口高度 */

  background-color: #eef2f5; /* 背景颜色 */
  
  display: flex;
  flex-direction: column;
}

header {
  z-index: 2; /* 最底层 */
}
main {
  flex:1; /* 使主体内容填充整个视图 */
  padding: 0px; /* 给主体内容添加内边距 */
  z-index: 1; /* 最底层 */
}

.app-footer {
  background-color: #3fc2d9; /* 页脚背景颜色 */
  color: white; /* 字体颜色 */
  padding: 5px;
  position: relative; /* 使页脚相对于容器 */
}
</style>
