<template>
  <div class="notification-container">
    <div class="sidebar">
      <h2>通知中心</h2>
      <button class="mark-all-read" @click="markAllAsRead">全部标为已读</button>
      <button class="mark-selected-read" @click="markSelectedAsRead" :disabled="selectedNotifications.length === 0">标为已读</button>
      <button class="delete-all" @click="deleteAllNotifications">全部删除</button>
      <button class="delete-selected" @click="deleteSelectedNotifications" :disabled="selectedNotifications.length === 0">删除所选通知</button>
    </div>
    <div class="main-content">
      <h2>系统通知</h2>
      <div v-if="sortedNotifications.length === 0" class="no-notifications">没有消息</div>
      <div v-else>
        <div
          v-for="notification in sortedNotifications"
          :key="notification.id"
          class="notification-item"
        >
          <input type="checkbox" v-model="selectedNotifications" :value="notification.id" />
          <div class="notification-content" :class="{ read: notification.is_read }">
            {{ notification.message }}
            <span class="status">{{ notification.is_read ? '已读' : '未读' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'NotificationComponent',
  data() {
    return {
      notifications: [],
      selectedNotifications: [],
    };
  },
  computed: {
    // 通过浅拷贝来排序，避免修改原数据
    sortedNotifications() {
      return this.notifications.slice().sort((a, b) => a.is_read - b.is_read);
    }
  },
  methods: {
    async fetchNotifications() {
      try {
        const response = await axios.get('/api/notification/notifications/');
        this.notifications = response.data;
      } catch (error) {
        console.error('获取通知失败:', error);
      }
    },
    async markAllAsRead() {
      try {
        await axios.post('/api/notification/mark_as_read/', {ids: this.notifications.map(n => n.id)});
        this.fetchNotifications(); // 重新获取通知
      } catch (error) {
        console.error('标记所有通知为已读失败:', error);
      }
    },
    async markSelectedAsRead() {
      if (this.selectedNotifications.length === 0) {
        alert("请先选择要标记为已读的通知");
        return;
      }
      try {
        await axios.post('/api/notification/mark_as_read/', {ids: this.selectedNotifications});
        this.fetchNotifications(); // 重新获取通知
        this.selectedNotifications = []; // 清空已选中
      } catch (error) {
        console.error('标记选中通知为已读失败:', error);
      }
    },
    async deleteAllNotifications() {
      try {
        await axios.post('/api/notification/delete_mark/', {ids: this.notifications.map(n => n.id)});
        this.fetchNotifications(); // 重新获取通知
      } catch (error) {
        console.error('删除所有通知失败:', error);
      }
    },
    async deleteSelectedNotifications() {
      if (this.selectedNotifications.length === 0) {
        alert("请先选择要删除的通知");
        return;
      }
      try {
        await axios.post('/api/notification/delete_mark/', {ids: this.selectedNotifications});
        this.fetchNotifications(); // 重新获取通知
        this.selectedNotifications = []; // 清空已选中
      } catch (error) {
        console.error('删除选中通知失败:', error);
      }
    }
  },
  mounted() {
    this.fetchNotifications();
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.fetchNotifications();
      }
    }
  }
};
</script>

<style scoped>
.notification-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 20px;
  height: 100vh;
}

.sidebar {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.sidebar h2 {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.sidebar button {
  margin-top: 10px;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
  
/* 全部标为已读按钮 */
.mark-all-read {
  background-color: #4CAF50; /* 绿色 */
}

.mark-all-read:hover {
  background-color: #45a049; /* 深绿 */
}

/* 标为已读按钮 */
.mark-selected-read {
  background-color: #2196F3; /* 蓝色 */
}

.mark-selected-read:hover {
  background-color: #1976D2; /* 深蓝 */
}

/* 全部删除按钮 */
.delete-all {
  background-color: #f44336; /* 红色 */
}

.delete-all:hover {
  background-color: #d32f2f; /* 深红 */
}

/* 删除所选通知按钮 */
.delete-selected {
  background-color: #FF9800; /* 橙色 */
}

.delete-selected:hover {
  background-color: #FB8C00; /* 深橙 */
}
  
.sidebar button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.main-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}

.notification-content {
  flex-grow: 1;
  position: relative;
}

.notification-content.read {
  color: #aaa;
}

.status {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 12px;
  color: gray;
}

.no-notifications {
  padding: 10px;
  text-align: center;
  color: gray;
}
</style>
