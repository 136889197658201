import { createStore } from 'vuex';

const store = createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('access_token'),
    username: localStorage.getItem('username') || null,
    access_token: localStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    avatar_url: localStorage.getItem('avatar_url') || null,
    hasMessage: localStorage.getItem('hasMessage') || false,
  },
  mutations: {
    login(state, payload) {
      state.isLoggedIn = true;
      state.username = payload.username;
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      localStorage.setItem('username', payload.username);
      localStorage.setItem('access_token', payload.access_token);
      localStorage.setItem('refresh_token', payload.refresh_token);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.username = null;
      state.access_token = null;
      state.refresh_token = null;
      localStorage.removeItem('username');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    saveAvatarUrl(state, payload) {
      state.avatar_url = payload;
      localStorage.setItem('avatar_url', payload);
    },
    setUnread(state) {
      state.hasMessage = true;
      localStorage.setItem('hasMessage', true);
    },
    clearUnread(state) {
      state.hasMessage = false;
      localStorage.setItem('hasMessage', false);
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('login', payload);
    },
    logout({ commit }) {
      commit('logout');
    },
    saveAvatarUrl({ commit }, payload) {
      commit('saveAvatarUrl', payload);
    },
    setUnread({ commit }) {
      commit('setUnread');
    },
    clearUnread({ commit }) {
      commit('clearUnread');
    },
  }
});

export default store;
